<template>
  <div class="users-home-container">
    <b-overlay :show="userBusy">
      <paginate :meta="usersMeta" place-holder="ئەزانىڭ تور نامىنى ياكى id نۇمۇرىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh" />
      <b-row>
        <b-col sm="6" md="3" v-for="(user, index) in users" :key="user.id">
          <portlet title=" " body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <a v-if="user.lock_logs.length" v-b-tooltip.hover="{customClass: 'custom-tooltip'}" :title="`قارا تىزىملىك سەۋەبى: ${ user.lock_logs[0].reason ? user.lock_logs[0].reason : 'خاتىرلەنمىگەن' } ${!user.is_locked ? '( ھازىر قارا تىزىملىك ئەمەس )' : ''}`" class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="fa fa-info-circle"></i>
              </a>
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="user.is_trust" @change="onChangeSwitch( user.id, 'is_trust', $event )"> كۆرۈش ھوقوق ھالىتى </b-checkbox>
                  <b-checkbox switch v-model="user.is_super_inviter" @change="onChangeSwitch( user.id, 'is_super_inviter', $event )"> سالونچىمۇ؟ </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item v-if="user.entrust" :to="{name: 'entrust.show', params: {entrust_id: user.entrust.id}}">
                  <i class="fa fa-book-open"></i> كېلىشىم تەپسىلاتى
                </b-dropdown-item>
                <b-dropdown-item @click="onClickUpdateVipExpires(user, index, $event)">
                  <i class="fa fa-user-clock"></i>ئالىي ئەزالىق ۋاقتىنى تەڭشەش
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="onClickSwitchLocked(user, index, $event)">
                  <i class="fa fa-user-lock"></i> قارا تىزىملىك ئۇچۇرى يېڭىلاش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickUpdateReward(user, index, $event)">
                  <i class="fa fa-chart-pie"></i> پايدا نىسبەت ئۇچۇرى يېڭىلاش
                </b-dropdown-item>
                <b-dropdown-item :to="{name: 'wallet.show', params: {user_id: user.id}}">
                  <i class="fa fa-wallet"></i> كىرىم چىقىم تەپسىلاتى
                </b-dropdown-item>
                <b-dropdown-item @click="onClickUserAccount(user)">
                  <i class="fa fa-id-card"></i>ئەزا سالاھىيىتى تەپسىلاتى
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar size="50px" text="!" :src="user.avatar" />
                    </div>
                    <div class="kt-widget__info text-truncate">
                      <a class="kt-widget__username">
                         {{ user.nickname ? user.nickname : 'نامەلۇم' }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">ئەزالىق id نۇمۇرى</span>
                        <span class="kt-widget__data">{{ user.id }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label"> VIP ئەزامۇ؟ </span>
                        <span class="kt-widget__data">{{ user.is_vip ? 'شۇنداق' : 'ياق'}}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كىرگەن ۋاقتى </span>
                        <span class="kt-widget__data">{{ user.time_ago }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!users.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، ھازىرچە مۇناسىۋەتلىك ئەزا ئۇچۇرى يوق ئىكەن." />
    </b-overlay>
    <user-switch-lock-modal v-model="switchLockedModalState" :busy="lockModalBusy" :lock-state="currentLockState" :user-id="currentUserId" @switching="onSwitching" @switched="onSwitched" @failed="onSwitchFailed" />
    <user-reward-modal v-model="rewardModalState" :user-id="currentUserId" :options="rewardOptions" @updated="onRewardUpdated" @filed="onRewardFailed" />
    <user-vip-modal v-model="vipModalState" :user-id="currentUserId" :options="rewardOptions" @updated="onVipUpdated" @filed="onVipFailed" />
    <user-account-modal v-model="accountModalState" :info="currentUser" />
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import userSwitchLockModal from "@v@/components/modals/user.switch.lock.modal";
  import userRewardModal from "@v@/components/modals/user.reward.modal";
  import userVipModal from "@v@/components/modals/user.vip_expires.modal";
  import userAccountModal from "@v@/components/modals/user.account.modal";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import userMixin from "@m@/user.mixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import {SET_USER_ERROR} from "@/store/modules/user.module";
  export default {
    name: "index",
    components: { Portlet, paginate, userSwitchLockModal, userRewardModal, NoResult, userVipModal, userAccountModal },
    mixins: [formBusyMixin, userMixin, commonMethodMixin],
    created() {
      this.getUsers(1, '');
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئەزا باشقۇرۇش', route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق ئەزالار'});
    },
    data(){
      return {
        userBusy: false,
        switchLockedModalState: false,
        currentLockState: false,
        currentUserId: null,
        vipModalState: null,
        lockModalBusy: false,
        rewardModalState: false,
        accountModalState: false,
        rewardOptions: {},
        page: 1,
        title: '',
        currentUser: {}
      }
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page;
        this.title = title;
        this.getUsers(page, title);
      },
      onRefresh(title){
        this.page = 1;
        this.title = title;
        this.getUsers(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'users',
          id, column, mutationType: SET_USER_ERROR,
          begin: ()=>{ this.showBusy('userBusy') },
          after: ()=>{ this.hideBusy('userBusy') },
        });
      },
      onClickSwitchLocked(user, index, e){
        let { is_locked, id } = user;
        this.setCurrentLockState(is_locked);
        this.setCurrentUserId(id);
        this.toggleSwitchLockedModalState();
      },
      onClickUpdateVipExpires(user, index, e){
        this.setCurrentUserId(user.id);
        this.showVipModal();
      },
      setCurrentLockState(currentLockState = 0){
        this.currentLockState = currentLockState;
      },
      setCurrentUserId(currentUserId = null){
        this.currentUserId = currentUserId;
      },
      toggleSwitchLockedModalState(){
        this.switchLockedModalState = ! this.switchLockedModalState;
      },
      onSwitching(){
        this.showBusy('lockModalBusy');
      },
      onSwitched(){
        this.hideBusy('lockModalBusy');
        this.toggleSwitchLockedModalState();
        let { page, title } = this;
        this.getUsers(page, title);
      },
      onSwitchFailed(){
        this.hideBusy('lockModalBusy');
      },

      onVipUpdated(){
        this.hideVipModal();
      },
      onVipFailed(){
        this.hideVipModal();
      },
      setVipModalState(state){
        this.vipModalState = state;
      },
      showVipModal(){
        this.setVipModalState(true);
      },
      hideVipModal(){
        this.setVipModalState(false);
      },

      setRewardOptions(rewardOptions = {}){
        this.rewardOptions = rewardOptions
      },
      toggleRewardModalState(){
        this.rewardModalState = ! this.rewardModalState;
      },
      onClickUpdateReward(user, index, e){
        let { profit_ratio, id } = user;
        this.setCurrentUserId(id);
        this.setRewardOptions(profit_ratio);
        this.toggleRewardModalState();
      },
      onRewardUpdated(){
        this.toggleRewardModalState();
      },
      onRewardFailed(){
        this.toggleRewardModalState();
      },
      onClickUserAccount(user){
        this.currentUser = user;
        this.$nextTick(()=>{
          this.accountModalState = true;
        })
      }
    }
  }
</script>

<style scoped>
  .locked-state {
    margin-top: 2px;
    font-size: 18px;
  }
</style>
